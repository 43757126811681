import { RefObject, useMemo, useState } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { useTranslation } from "next-i18next";

import { useDebounce } from "@sellernote/_shared/src/utils/common/hook";
import { InputSearchProps } from "@sellernote/_sds-v2/src/components/form/InputSearch";
import PlaneIcon from "@sellernote/_sds-v2/src/components/svgIcons/PlaneIcon";
import Ship3Icon from "@sellernote/_sds-v2/src/components/svgIcons/Ship3Icon";
import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";

import {
  createSchedulePortOption,
  getPortType,
  getRecommendCategoryOptionList,
} from "../utils";

import InputSearchWithPortOptions, {
  PortInfoType,
} from "../../../components/InputSearchWithPortOptions";
import useGetPortOptions from "../../../components/InputSearchWithPortOptions/hooks/useGetPortOptions";
import { FocusInputHandler } from "../../../components/InputSearchWithPortOptions/types";
import {
  checkIsWarehouse,
  getPortLabel,
} from "../../../components/InputSearchWithPortOptions/utils";

import {
  FORWARDING_SCHEDULE_ACTIONS,
  FORWARDING_SCHEDULE_SELECTORS,
} from "../../../jotaiStates/forwarding/schedule";
import { SCHEDULE_PORT_CATEGORY_INFO_LIST } from "../constants";
import { useSearch } from "../SearchContext";

interface Props {
  isMobile?: boolean;
  podOptionHandlerListRef: RefObject<FocusInputHandler>;
}

export default function Pod({
  isMobile = false,
  podOptionHandlerListRef,
}: Props) {
  const [searchTerm, setSearchTerm] = useState("");

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const { t } = useTranslation("page-forwarding-schedule");

  const [{ freightType, podId }, setSearch] = useSearch();

  const { selectedPol, selectedPod: localSelectedPod } = useAtomValue(
    FORWARDING_SCHEDULE_SELECTORS.SELECTED_PORT_INFO
  );

  const updateSelectedPortInfo = useSetAtom(
    FORWARDING_SCHEDULE_ACTIONS.UPDATE_SELECTED_PORT_INFO
  );

  // 출발지가 한국이 아닌 국가를 선택한 경우 도착지는 한국 국가만 노출
  const country = (() => {
    if (!selectedPol) return undefined;

    return selectedPol.country !== "KR" ? "KR" : undefined;
  })();

  // 출발지에서 선택한 국가는 옵션에서 제외시킴
  const exceptCountry = (() => {
    if (selectedPol?.country) return [selectedPol?.country];

    return undefined;
  })();

  const {
    rawPortList,
    portOptionList,
    isLoading: isLoadingPortOptionList,
  } = useGetPortOptions({
    pointType: "end",
    portType: getPortType(freightType),
    searchTerm: debouncedSearchTerm,
    categoryInfoList: SCHEDULE_PORT_CATEGORY_INFO_LIST,
    transformToPortOption: createSchedulePortOption,
    ...(!podId &&
      selectedPol && {
        country,
        exceptCountry,
      }),
  });

  /**
   * useSearch hook에 selectedPod 값이 있지만 queryString을 반영하기 위해 API 호출로 값을 가져옴
   */
  const selectedPod = useMemo(() => {
    if (!podId) return undefined;

    if (localSelectedPod) return localSelectedPod;

    return rawPortList.find((port) => port.id === podId);
  }, [podId, rawPortList, localSelectedPod]);

  const podRecommendationList = useMemo(() => {
    if (debouncedSearchTerm) return undefined;

    return getRecommendCategoryOptionList({
      portList: rawPortList,
      freightType,
      locationType: "pod",
      portCountryOfAnotherLocationType: rawPortList?.find(
        (port) => port.id === podId
      )?.country,
    });
  }, [freightType, podId, rawPortList, debouncedSearchTerm]);

  const handlePolSearchTermChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value: searchTerm } = e.target;

    setSearchTerm(searchTerm);
  };

  const handlePodSelect = (portInfo: PortInfoType) => {
    if (checkIsWarehouse(portInfo) || !portInfo) return;

    const { id: podId, portGroupId: podPortGroupId } = portInfo;

    setSearch({
      podId,
      podPortGroupId,
    });

    updateSelectedPortInfo({
      type: "pod",
      portInfo,
    });
  };

  const handlePodReset = () => {
    setSearch({ podId: null, podPortGroupId: null });

    updateSelectedPortInfo({
      type: "pod",
      portInfo: undefined,
    });

    setSearchTerm("");
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isBackspaceKey = e.key === "Backspace";

    if (!selectedPod || !isBackspaceKey) return;

    handlePodReset();
  };

  const isLoading = (() => {
    if (!searchTerm || Boolean(selectedPod)) return false;

    return searchTerm !== debouncedSearchTerm || isLoadingPortOptionList;
  })();

  const inputValue = (() => {
    if (!selectedPod) return searchTerm;

    return getPortLabel(selectedPod);
  })();

  const leftIconInfo: InputSearchProps["leftIconInfo"] = (() => {
    if (!selectedPod) return undefined;

    return {
      color: COLOR.bk_80,
      Icon: selectedPod.type === "sea" ? Ship3Icon : PlaneIcon,
    };
  })();

  return (
    <InputSearchWithPortOptions
      className="custom-label-color"
      {...(!isMobile && { width: 296 })}
      labelInfo={{
        label: (
          <>
            {t("page-forwarding-schedule:도착지")}
            <span className="required">*</span>
          </>
        ),
        position: "top",
      }}
      leftIconInfo={leftIconInfo}
      placeholder={t("page-forwarding-schedule:항구/공항을_입력해주세요.")}
      onChange={handlePolSearchTermChange}
      isLoading={isLoading}
      searchTerm={inputValue}
      searchSourceList={portOptionList}
      focusSearchSourceList={podRecommendationList}
      onSelect={handlePodSelect}
      onReset={handlePodReset}
      onKeyDown={handleKeyDown}
      focusHandlerRef={podOptionHandlerListRef}
    />
  );
}
