import { GET_USER_PORT_LIST_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/common";
import {
  ShipdaCurrentLanguage,
  ShipdaCurrentLanguageType,
} from "@sellernote/_shared/src/i18n/i18nForShipda";
import { UserPort } from "@sellernote/_shared/src/types/common/common";
import { ShipmentType } from "@sellernote/_shared/src/types/forwarding/common";
import { FreightTypeOption } from "@sellernote/_shared/src/types/forwarding/schedule";
import PlaneIcon from "@sellernote/_sds-v2/src/components/svgIcons/PlaneIcon";
import Ship3Icon from "@sellernote/_sds-v2/src/components/svgIcons/Ship3Icon";

import {
  InputSearchWithCategoryPortOption,
  InputSearchWithPortOption,
} from "../../components/InputSearchWithPortOptions/types";
import { getPortLabel } from "../../components/InputSearchWithPortOptions/utils";

import {
  POD_CODE_RECOMMENDATION_LIST_FOR_AIR_EXPORTATION,
  POD_CODE_RECOMMENDATION_LIST_FOR_AIR_IMPORTATION,
  POD_CODE_RECOMMENDATION_LIST_FOR_SEA_EXPORTATION,
  POD_CODE_RECOMMENDATION_LIST_FOR_SEA_IMPORTATION,
  POL_CODE_RECOMMENDATION_LIST_FOR_AIR_EXPORTATION,
  POL_CODE_RECOMMENDATION_LIST_FOR_AIR_IMPORTATION,
  POL_CODE_RECOMMENDATION_LIST_FOR_SEA_EXPORTATION,
  POL_CODE_RECOMMENDATION_LIST_FOR_SEA_IMPORTATION,
} from "./data";

/** 현재 언어에 따라 노출되는 문구를 처리하는 함수 */
const checkCurrentLanguage = <T,>(
  languageMap: Record<ShipdaCurrentLanguageType, T>
): T => languageMap[ShipdaCurrentLanguage.currentLanguage];

type LocationType = "pol" | "pod";

/**
 * (디자인 요청) 랜덤으로 5개 항목 노출
 */
const getRandomPortList = (arr: string[]) => {
  if (arr.length <= 5) {
    return arr;
  }

  return arr.sort(() => 0.5 - Math.random()).slice(0, 5);
};

const getOption = (port: UserPort): InputSearchWithPortOption<number> => ({
  label: getPortLabel(port),
  value: port.id,
  iconInfo: {
    Icon: port.type === "air" ? PlaneIcon : Ship3Icon,
    position: "left",
  },
  portInfo: port,
});

const getOptionList = (
  list: GET_USER_PORT_LIST_RES | undefined
): InputSearchWithPortOption<number>[] => (list ?? []).map(getOption);

const getRecommendationCodeList = ({
  locationType,
  freightType,
  shipmentType,
}: {
  locationType: LocationType;
  freightType: FreightTypeOption;
  shipmentType: ShipmentType;
}) =>
  ({
    POL_CODE_RECOMMENDATION_LIST_FOR_SEA_EXPORTATION: getRandomPortList(
      POL_CODE_RECOMMENDATION_LIST_FOR_SEA_EXPORTATION
    ),
    POL_CODE_RECOMMENDATION_LIST_FOR_SEA_IMPORTATION: getRandomPortList(
      POL_CODE_RECOMMENDATION_LIST_FOR_SEA_IMPORTATION
    ),
    POL_CODE_RECOMMENDATION_LIST_FOR_AIR_EXPORTATION: getRandomPortList(
      POL_CODE_RECOMMENDATION_LIST_FOR_AIR_EXPORTATION
    ),
    POL_CODE_RECOMMENDATION_LIST_FOR_AIR_IMPORTATION: getRandomPortList(
      POL_CODE_RECOMMENDATION_LIST_FOR_AIR_IMPORTATION
    ),
    POD_CODE_RECOMMENDATION_LIST_FOR_SEA_EXPORTATION: getRandomPortList(
      POD_CODE_RECOMMENDATION_LIST_FOR_SEA_EXPORTATION
    ),
    POD_CODE_RECOMMENDATION_LIST_FOR_SEA_IMPORTATION: getRandomPortList(
      POD_CODE_RECOMMENDATION_LIST_FOR_SEA_IMPORTATION
    ),
    POD_CODE_RECOMMENDATION_LIST_FOR_AIR_EXPORTATION: getRandomPortList(
      POD_CODE_RECOMMENDATION_LIST_FOR_AIR_EXPORTATION
    ),
    POD_CODE_RECOMMENDATION_LIST_FOR_AIR_IMPORTATION: getRandomPortList(
      POD_CODE_RECOMMENDATION_LIST_FOR_AIR_IMPORTATION
    ),
  }[
    `${
      locationType.toUpperCase() as Uppercase<LocationType>
    }_CODE_RECOMMENDATION_LIST_FOR_${
      freightType === FreightTypeOption.Air ? "AIR" : "SEA"
    }_${shipmentType.toUpperCase() as Uppercase<ShipmentType>}`
  ]);

const getPortOptionListByCode = ({
  recommendationCodeList,
  portList,
}: {
  recommendationCodeList: string[];
  portList: GET_USER_PORT_LIST_RES | undefined;
}): InputSearchWithPortOption<number>[] => {
  const recommendPortOptionList = recommendationCodeList
    .map((code) => {
      const port = portList?.find((port) => port.code === code);

      if (!port) return null;

      return getOption(port);
    })
    .filter(Boolean) as InputSearchWithPortOption<number>[];

  return recommendPortOptionList;
};

const getRecommendCategoryOptionList = ({
  portList,
  freightType,
  locationType,
  portCountryOfAnotherLocationType,
}: {
  portList: GET_USER_PORT_LIST_RES | undefined;
  freightType: FreightTypeOption;
  locationType: LocationType;
  portCountryOfAnotherLocationType: string | undefined;
}): InputSearchWithCategoryPortOption<number>[] => {
  const isAnotherLocationTypeSelected = Boolean(
    portCountryOfAnotherLocationType
  );

  /**
   * 다른 하나의 출발/도착지가 선택되어 있지 않은 경우에는 수출, 수입 순서로 추천 목록을 모두 보여준다.
   */
  if (!isAnotherLocationTypeSelected) {
    return [
      {
        category: {
          label: "수출",
          keyword: "exportation",
        },
        optionList: getPortOptionListByCode({
          recommendationCodeList: getRecommendationCodeList({
            locationType,
            freightType,
            shipmentType: "exportation",
          }),
          portList,
        }),
      },
      {
        category: {
          label: "수입",
          keyword: "importation",
        },
        optionList: getPortOptionListByCode({
          recommendationCodeList: getRecommendationCodeList({
            locationType,
            freightType,
            shipmentType: "importation",
          }),
          portList,
        }),
      },
    ];
  }

  /**
   * 다른 하나의 출발/도착지가 선택되어 있는 경우에는 선택된 port가 한국인지(수출/수입인지) 여부에 따라 해당하는 수출/수입 추천 목록을 보여준다.
   */
  const isLocationTypePol = locationType === "pol";
  const isAnotherLocationPortInKR = portCountryOfAnotherLocationType === "KR";

  if (isLocationTypePol) {
    if (isAnotherLocationPortInKR) {
      return [
        {
          category: {
            label: "수입",
            keyword: "importation",
          },
          optionList: getPortOptionListByCode({
            recommendationCodeList: getRecommendationCodeList({
              locationType,
              freightType,
              shipmentType: "importation",
            }),
            portList,
          }),
        },
      ];
    }

    return [
      {
        category: {
          label: "수출",
          keyword: "exportation",
        },
        optionList: getPortOptionListByCode({
          recommendationCodeList: getRecommendationCodeList({
            locationType,
            freightType,
            shipmentType: "exportation",
          }),
          portList,
        }),
      },
    ];
  }

  if (isAnotherLocationPortInKR) {
    return [
      {
        category: {
          label: "수출",
          keyword: "exportation",
        },
        optionList: getPortOptionListByCode({
          recommendationCodeList: getRecommendationCodeList({
            locationType,
            freightType,
            shipmentType: "exportation",
          }),
          portList,
        }),
      },
    ];
  }

  return [
    {
      category: {
        label: "수입",
        keyword: "importation",
      },
      optionList: getPortOptionListByCode({
        recommendationCodeList: getRecommendationCodeList({
          locationType,
          freightType,
          shipmentType: "importation",
        }),
        portList,
      }),
    },
  ];
};

/**
 * freightType은 default를 FCL로 설정했기 때문에, AIR, FCL, LCL 이외의 값이 들어올 수 없음
 */
const getPortType = (freightType: FreightTypeOption) =>
  freightType === FreightTypeOption.Air ? "air" : "sea";

/** UserPort 정보를 InputSearchWithPortOption 구조로 변환하는 함수 */
const createSchedulePortOption = (
  portInfo: UserPort
): InputSearchWithPortOption<number> => ({
  value: portInfo.id,
  label: getPortLabel(portInfo),
  portInfo,
  iconInfo: {
    position: "left",
    Icon: portInfo.type === "air" ? PlaneIcon : Ship3Icon,
  },
});

export {
  getOptionList,
  getRecommendCategoryOptionList,
  getPortType,
  createSchedulePortOption,
  checkCurrentLanguage,
};
